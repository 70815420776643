import { subMinutes } from 'date-fns'

export const SDRQueue = [
  {
    id: 1,
    name: 'Eric Donovan',
    phone: 'hidden',
    email: 'hidden',
    lost_reason: null,
    pool_reason: null,
    pool_release_user_id: null,
    archived_at: null,
    created_at: subMinutes(new Date(), 10).toISOString(),
    updated_at: subMinutes(new Date(), 10).toISOString(),
    budget_min: null,
    budget_max: null,
    local_budget_min: null,
    local_budget_max: null,
    assigned_at: null,
    currency: null,
    stage: {
      id: 20,
      code: 'queue:new',
      name: 'New',
    },
    step: null,
    country: { id: 234, iso2: 'AE', name: 'United Arab Emirates' },
    phone_country: { id: 234, iso2: 'AE', name: 'United Arab Emirates', phone_code: '971' },
    language: {
      id: 5,
      code: 'en',
      name: 'English',
    },
    timezone: [{ id: 382, country_id: 234, name: 'Asia/Dubai', offset: 14400 }],
    assignee: null,
    reporter: null,
    campaign: null,
    missed_calls_count: 0,
    has_calls: false,
    tags: [],
    parent_id: null,
    country_id: 234,
    phone_country_id: 234,
    language_id: 40,
    lead_source_id: 3,
    timezone_id: 14,
    activity_status_code: null,
    execution_time: '0.075531005859375s',
    timeDifference: '3 minutes',
  },
  {
    id: 2,
    name: 'Eric Donovan',
    phone: 'hidden',
    email: 'hidden',
    lost_reason: null,
    pool_reason: null,
    pool_release_user_id: null,
    archived_at: null,
    created_at: subMinutes(new Date(), 12).toISOString(),
    updated_at: subMinutes(new Date(), 12).toISOString(),
    budget_min: null,
    budget_max: null,
    local_budget_min: null,
    local_budget_max: null,
    assigned_at: null,
    currency: null,
    stage: {
      id: 20,
      code: 'queue:new',
      name: 'New',
    },
    step: null,
    country: { id: 234, iso2: 'AE', name: 'United Arab Emirates' },
    phone_country: { id: 234, iso2: 'AE', name: 'United Arab Emirates', phone_code: '971' },
    language: {
      id: 5,
      code: 'en',
      name: 'English',
    },
    timezone: [{ id: 382, country_id: 234, name: 'Asia/Dubai', offset: 14400 }],
    assignee: null,
    reporter: null,
    campaign: null,
    missed_calls_count: 0,
    has_calls: false,
    tags: [],
    parent_id: null,
    country_id: 234,
    phone_country_id: 234,
    language_id: 40,
    lead_source_id: 3,
    timezone_id: 14,
    activity_status_code: null,
    execution_time: '0.075531005859375s',
    timeDifference: '7 minutes',
  },
  {
    id: 3,
    name: 'Eric Donovan',
    phone: 'hidden',
    email: 'hidden',
    lost_reason: null,
    pool_reason: null,
    pool_release_user_id: null,
    archived_at: null,
    created_at: subMinutes(new Date(), 14).toISOString(),
    updated_at: subMinutes(new Date(), 14).toISOString(),
    budget_min: null,
    budget_max: null,
    local_budget_min: null,
    local_budget_max: null,
    assigned_at: null,
    currency: null,
    stage: {
      id: 20,
      code: 'queue:new',
      name: 'New',
    },
    step: null,
    country: { id: 234, iso2: 'AE', name: 'United Arab Emirates' },
    phone_country: { id: 234, iso2: 'AE', name: 'United Arab Emirates', phone_code: '971' },
    language: {
      id: 5,
      code: 'en',
      name: 'English',
    },
    timezone: [{ id: 382, country_id: 234, name: 'Asia/Dubai', offset: 14400 }],
    assignee: null,
    reporter: null,
    campaign: null,
    missed_calls_count: 0,
    has_calls: false,
    tags: [],
    parent_id: null,
    country_id: 234,
    phone_country_id: 234,
    language_id: 40,
    lead_source_id: 3,
    timezone_id: 14,
    activity_status_code: null,
    execution_time: '0.075531005859375s',
    timeDifference: '8 minutes',
  },
]

export const SDRQueueLead = {
  id: 1,
  name: 'Eric Donovan',
  phone: '324343434',
  email: null,
  area_min: null,
  area_max: null,
  budget_min: null,
  budget_max: null,
  local_budget_min: null,
  local_budget_max: null,
  ready_to_move: null,
  lost_reason: null,
  pool_reason: 'Conflicts of interests; s',
  closed_at: null,
  archived_at: null,
  created_at: '2023-10-18T11:54:22.000000Z',
  updated_at: '2024-03-11T16:04:04.000000Z',
  currency_id: null,
  assignee_id: 11,
  reporter_id: 11,
  lead_source_id: 1,
  lead_type_id: null,
  pipeline_id: 2,
  pipeline_stage_id: 10,
  pipeline_step_id: 35,
  country_id: 234,
  phone_country_id: 234,
  language_id: 5,
  timezone_id: 2,
  currency: null,
  pipeline: {
    id: 2,
    name: 'Queue',
    code: 'queue',
  },
  stage: {
    id: 1,
    code: 'sales:new',
    name: 'New',
  },
  step: {
    id: 35,
    code: 'pool:conflict-of-interests',
    name: 'Conflicts of interests',
  },
  interests: [
    {
      id: 10,
      name: 'Sea/Water View',
    },
    {
      id: 13,
      name: 'Private Pool',
    },
    {
      id: 8,
      name: 'Barbecue Area',
    },
    {
      id: 9,
      name: "Children's Play Area",
    },
    {
      id: 14,
      name: 'Private Garden',
    },
  ],
  types: [
    {
      id: 3,
      name: 'Villa',
    },
    {
      id: 4,
      name: 'Penthouse',
    },
    {
      id: 7,
      name: 'Full Floor',
    },
    {
      id: 6,
      name: 'Half Floor',
    },
    {
      id: 1,
      name: 'Apartment',
    },
    {
      id: 2,
      name: 'Duplex',
    },
    {
      id: 3,
      name: 'Villa',
    },
  ],
  locations: [
    {
      id: 35,
      name: 'Al Sufouh',
      pivot: {
        lead_id: 1,
        location_id: 35,
      },
    },
    {
      id: 42,
      name: 'Arabian Ranches 3',
      pivot: {
        lead_id: 1,
        location_id: 42,
      },
    },
    {
      id: 74,
      name: 'Dubai Sports City',
      pivot: {
        lead_id: 1,
        location_id: 74,
      },
    },
    {
      id: 107,
      name: 'Ras Al Khor',
      pivot: {
        lead_id: 1,
        location_id: 107,
      },
    },
  ],
  bedrooms: [],
  country: { id: 234, iso2: 'AE', name: 'United Arab Emirates' },
  phone_country: { id: 234, iso2: 'AE', name: 'United Arab Emirates', phone_code: '971' },
  language: {
    id: 5,
    code: 'en',
    name: 'English',
  },
  timezone: [{ id: 382, country_id: 234, name: 'Asia/Dubai', offset: 14400 }],
  campaign: null,
  rotation: null,
  missed_calls_count: 0,
  has_calls: true,
  tags: [
    { id: 9, name: 'In UAE', code: 'in-uae', type: 'App\\Models\\Lead\\Lead' },
    { id: 3, name: 'HQ Lead', code: 'hq-lead', type: 'App\\Models\\Lead\\Lead' },
  ],
  parent_id: null,
  activity_status_code: 'overdue',
  deals: [],
}

export const SDRQueueLeadHistory = [
  {
    id: 51294,
    title: 'Call with Eric Donovan',
    lead_id: 8136,
    participants: [],
    location: null,
    meet_url: null,
    comment: null,
    additional: null,
    link: null,
    duration: 0,
    schedule_from: '2024-03-21T08:00:00.000000Z',
    schedule_to: '2024-03-21T08:00:00.000000Z',
    from: null,
    to: null,
    direction: null,
    created_at: '2024-03-21T07:29:30.000000Z',
    updated_at: '2024-03-21T09:00:06.000000Z',
    user: {
      id: 999999,
      name: 'Example Admin',
      image:
        'https://m.media-amazon.com/images/M/MV5BMTg1MTkxODgzMF5BMl5BanBnXkFtZTgwMjExMjgyNzM@._V1_QL75_UX280_CR0,24,280,280_.jpg',
    },
    creator_user: null,
    type: {
      id: 6,
      code: 'calendar_call',
      name: 'Call',
      description: 'User created calendar call event',
    },
    outcome: null,
    status: {
      id: 3,
      name: 'Overdue',
      code: 'overdue',
    },
    due_date: '2024-03-22T09:00:00.000000Z',
    sub_type: null,
    is_pinned: false,
    data: {
      participants: [],
      notifications: [
        {
          sms: 35,
        },
        {
          popup: 15,
        },
      ],
    },
  },
  {
    id: 53952,
    title: 'Pipeline Stage Change',
    lead_id: 8,
    participants: [],
    location: null,
    meet_url: null,
    comment: null,
    additional: [
      {
        field: 'Pipeline',
        value: 'Initial Sales',
      },
      {
        field: 'Pipeline stage',
        value: 'Closed',
      },
      {
        field: 'Reason',
        value: 'Won',
      },
      {
        field: 'Lost reason',
        value: null,
      },
      {
        field: 'Date when lead was closed',
        value: '2024-03-20 11:52:40',
      },
    ],
    link: null,
    duration: 0,
    schedule_from: null,
    schedule_to: null,
    from: null,
    to: null,
    direction: null,
    created_at: '2024-03-20T11:52:40.000000Z',
    updated_at: '2024-03-20T11:52:40.000000Z',
    user: {
      id: 12,
      name: 'Eric Donovan',
    },
    creator_user: null,
    type: {
      id: 4,
      code: 'transition',
      name: 'Pipeline Stage Change',
      description: 'Transition between pipeline stages.',
    },
    outcome: null,
    status: null,
    due_date: null,
    sub_type: null,
    data: null,
  },
  {
    id: 51797,
    title: 'Pipeline Stage Change',
    lead_id: 8,
    participants: [],
    location: null,
    meet_url: null,
    comment: null,
    additional: [
      {
        field: 'Pipeline',
        value: 'Initial Sales',
      },
      {
        field: 'Pipeline stage',
        value: 'Interested',
      },
      {
        field: 'Reason',
        value: null,
      },
      {
        field: 'Lost reason',
        value: null,
      },
    ],
    link: null,
    duration: 0,
    schedule_from: null,
    schedule_to: null,
    from: null,
    to: null,
    direction: null,
    created_at: '2024-03-05T07:40:49.000000Z',
    updated_at: '2024-03-05T07:40:49.000000Z',
    user: {
      id: 29,
      name: 'Eric Donovan',
      image: null,
    },
    creator_user: null,
    type: {
      id: 4,
      code: 'transition',
      name: 'Pipeline Stage Change',
      description: 'Transition between pipeline stages.',
    },
    outcome: null,
    status: null,
    due_date: null,
    sub_type: null,
    data: null,
  },
]
